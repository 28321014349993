@import url("https://fonts.googleapis.com/css?family=Cabin:400,500,600,700|Josefin+Sans:300,300i,400,600");
Core Stuff
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

body {
  font-size: 1rem;
  line-height: 1.7;
  color: #606d6e; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #454B4D; }

a {
  color: #1F8CD6;
  text-decoration: none; }

a:hover {
  color: #175E91; }

pre {
  background: #F0F0F0;
  margin: 1rem 0;
  border-radius: 2px; }

blockquote {
  border-left: 10px solid #eee;
  margin: 0;
  padding: 0 2rem; }

/* Utility Classes */
.wrapper {
  margin: 0 3rem; }

.padding {
  padding: 3rem 1rem; }

.left {
  float: left; }

.right {
  float: right; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

/* Content Styling */
.header .padding {
  padding: 1rem 0; }

.header {
  background-color: #1F8DD6;
  color: #eee; }

.header a {
  color: #fff; }

.header .logo {
  font-size: 1.7rem;
  text-transform: uppercase; }

.footer {
  background-color: #eee; }

/* Menu Settings */
.main-nav ul {
  text-align: center;
  letter-spacing: -1em;
  margin: 0;
  padding: 0; }

.main-nav ul li {
  display: inline-block;
  letter-spacing: normal; }

.main-nav ul li a {
  position: relative;
  display: block;
  line-height: 45px;
  color: #fff;
  padding: 0 20px;
  white-space: nowrap; }

.main-nav > ul > li > a {
  border-radius: 2px; }

/*Active dropdown nav item */
.main-nav ul li:hover > a {
  background-color: #175E91; }

/* Selected Dropdown nav item */
.main-nav ul li.selected > a {
  background-color: #fff;
  color: #175E91; }

/* Dropdown CSS */
.main-nav ul li {
  position: relative; }

.main-nav ul li ul {
  position: absolute;
  background-color: #1F8DD6;
  min-width: 100%;
  text-align: left;
  z-index: 999;
  display: none; }

.main-nav ul li ul li {
  display: block; }

/* Dropdown CSS */
.main-nav ul li ul ul {
  left: 100%;
  top: 0; }

/* Active on Hover */
.main-nav li:hover > ul {
  display: block; }

/* Child Indicator */
.main-nav .has-children > a {
  padding-right: 30px; }

.main-nav .has-children > a:after {
  font-family: FontAwesome;
  content: '\f107';
  position: absolute;
  display: inline-block;
  right: 8px;
  top: 0; }

.main-nav .has-children .has-children > a:after {
  content: '\f105'; }

body {
  margin: 0; }

#header {
  position: relative;
  z-index: 100; }

.navbar {
  text-align: center; }
  .navbar img {
    height: 200px;
    width: auto;
    margin: 20px 50px; }
    @media screen and (max-width: 768px) {
      .navbar img {
        max-height: 150px;
        max-width: 150px;
        width: 100%;
        height: auto;
        margin: 0; } }
  .navbar .nav-content {
    display: flex;
    justify-content: center;
    align-items: center; }
    .navbar .nav-content h3 {
      flex: 1;
      font-size: 2rem;
      text-align: right;
      color: #3a295b; }
      .navbar .nav-content h3:last-child {
        text-align: left; }
    .navbar .nav-content .img-container {
      flex: 1; }
    @media screen and (max-width: 768px) {
      .navbar .nav-content {
        padding: 10px; }
        .navbar .nav-content p {
          text-align: center; }
          .navbar .nav-content p:last-child {
            text-align: center; }
        .navbar .nav-content .img-container {
          padding: 10px; } }
  .navbar .nav-block {
    position: relative;
    width: 100%;
    background: transparent; }
  .navbar .logo {
    display: none;
    transform: translateY(-55px);
    animation: dropOut .5s ease-in-out; }
    .navbar .logo img {
      height: 50px;
      width: auto;
      margin: 0; }
  .navbar ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    list-style: none;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    height: 55px;
    align-items: center; }
    .navbar ul li {
      list-style-type: none;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-top: 2px solid transparent;
      transition: border .25s linear;
      overflow: hidden; }
      .navbar ul li a {
        color: #3a295b; }
        .navbar ul li a.active {
          font-weight: 800;
          border-bottom: 2px solid #3a295b; }
  .navbar .line {
    z-index: -1;
    position: absolute;
    border-top: 2px solid transparent;
    transform: translateX(-60px); }
    @media screen and (max-width: 768px) {
      .navbar .line {
        display: none; } }
  .navbar .line.in {
    border-color: #3a295b;
    transform: none; }
  .navbar .line.leaving {
    border-color: transparent;
    transform: translateX(60px); }
  .navbar li,
  .navbar .line {
    transition: all .35s ease-in-out; }
  .navbar .nav-toggle {
    display: none; }
  .navbar.fixed .nav-block {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    background-color: #fff; }
  .navbar.fixed .logo {
    flex: 1 1;
    text-align: left;
    display: flex;
    transform: translateY(0);
    animation: dropIn .5s ease-in-out;
    z-index: 3; }
    .navbar.fixed .logo.in {
      background-color: #fff; }
    .navbar.fixed .logo a {
      display: flex;
      align-items: center;
      color: #3a295b; }
      .navbar.fixed .logo a img {
        margin: 0 10px; }
  .navbar.fixed ul {
    flex: 1 1;
    z-index: 2; }
    @media screen and (max-width: 768px) {
      .navbar.fixed ul {
        opacity: 0;
        position: absolute;
        display: block;
        transform: translateY(-500px);
        transition: all .5s ease-in-out;
        top: 60px; }
        .navbar.fixed ul li {
          transition: all .5s ease-in-out; }
        .navbar.fixed ul.open {
          transform: translateY(0);
          opacity: 1;
          width: 100%;
          height: 100%;
          transition: all .5s ease-in-out; }
          .navbar.fixed ul.open li {
            transform: translateY(0);
            background-color: white;
            transition: all .5s ease-in-out; } }
  .navbar.fixed .line {
    border-top-width: 6px; }
  .navbar.fixed .nav-bottom {
    background-color: #3a295b;
    width: 100%; }
  .navbar.fixed .nav-toggle {
    display: none; }
    @media screen and (max-width: 768px) {
      .navbar.fixed .nav-toggle {
        display: block;
        height: 60px;
        width: 55px;
        padding: 0;
        z-index: 3;
        background-color: transparent;
        border: none; }
        .navbar.fixed .nav-toggle:focus, .navbar.fixed .nav-toggle:active {
          outline: none; }
        .navbar.fixed .nav-toggle svg {
          height: 40px;
          width: 55px; }
          .navbar.fixed .nav-toggle svg line {
            transition: all .5s ease-in-out; }
          .navbar.fixed .nav-toggle svg #left, .navbar.fixed .nav-toggle svg #right {
            transform-origin: 18.83px 5.17px; }
          .navbar.fixed .nav-toggle svg #left-2, .navbar.fixed .nav-toggle svg #right-2 {
            transform-origin: 18.83px 21.17px; }
          .navbar.fixed .nav-toggle svg #left-3, .navbar.fixed .nav-toggle svg #right-3 {
            transform-origin: 18.83px 37.17px; }
        .navbar.fixed .nav-toggle.open svg line {
          transition: all .5s ease-in-out; }
        .navbar.fixed .nav-toggle.open #left, .navbar.fixed .nav-toggle.open #left-2, .navbar.fixed .nav-toggle.open #left-3 {
          transform: rotate(90deg) translateX(12px); }
        .navbar.fixed .nav-toggle.open #right, .navbar.fixed .nav-toggle.open #right-2, .navbar.fixed .nav-toggle.open #right-3 {
          transform: rotate(-90deg) translateX(-12px); } }

@keyframes dropIn {
  0% {
    transform: translateY(-55px);
    flex: 0 0; }
  100% {
    transform: translateY(0);
    flex: 1 1;
    text-align: left; } }

.hero {
  min-height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 50px 20px;
  text-align: center;
  color: #3C3C3C; }
  .hero.dark h1, .hero.dark .h1, .hero.dark h3, .hero.dark .h3, .hero.dark h2, .hero.dark .h2, .hero.dark h4, .hero.dark .h4, .hero.dark h5, .hero.dark .h5, .hero.dark h6, .hero.dark .h6, .hero.dark p, .hero.dark .p {
    color: #C3C3C3 !important; }

.gallery {
  display: flex;
  justify-content: flex-start;
  padding: 20px 30px; }
  .gallery .img-container {
    margin: 15px;
    transition: all .5s ease-in-out;
    line-height: 0; }
    .gallery .img-container:hover {
      cursor: pointer; }
  .gallery .gallery-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.55);
    display: none; }
    .gallery .gallery-modal.open {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 101; }
    .gallery .gallery-modal .modal-body {
      height: auto;
      max-width: 90%;
      max-height: 90%;
      padding: 20px;
      background-color: white;
      position: relative;
      text-align: center;
      overflow: scroll; }
      .gallery .gallery-modal .modal-body .close {
        position: absolute;
        top: 5px;
        right: 5px;
        transition: all .25s linear; }
        .gallery .gallery-modal .modal-body .close:hover {
          cursor: pointer;
          color: #939696; }
      .gallery .gallery-modal .modal-body img {
        max-width: 800px;
        height: auto; }
  .gallery.default {
    flex-wrap: wrap; }
    .gallery.default img {
      height: 300px;
      width: auto; }
      @media screen and (max-width: 768px) {
        .gallery.default img {
          height: auto;
          width: 100%; } }
    .gallery.default .img-container {
      position: relative;
      flex: 1 1 auto;
      text-align: center; }
      .gallery.default .img-container .img-info {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #fff;
        transition: opacity .35s ease-in-out; }
      .gallery.default .img-container:hover .img-info {
        opacity: 1; }
  .gallery.collection {
    padding: 20px 0;
    max-height: 500px; }
    .gallery.collection.slick-initialized .slick-track {
      display: flex;
      align-items: center; }
    .gallery.collection img {
      height: 500px;
      width: auto; }
    .gallery.collection .img-container {
      margin: 0; }
      .gallery.collection .img-container:hover {
        box-shadow: none;
        cursor: pointer; }
      .gallery.collection .img-container:focus {
        outline: none; }
    .gallery.collection .slick-arrow {
      z-index: 1;
      height: auto;
      width: auto; }
      .gallery.collection .slick-arrow:before {
        color: #8B8B8B;
        font-size: 2.5rem; }
    .gallery.collection .slick-prev {
      left: 0; }
    .gallery.collection .slick-next {
      right: 0; }

html, body {
  font-size: 16px; }

body {
  font-family: "Cabin", sans-serif;
  color: #3C3C3C; }

h1, .h1 {
  font-size: 6.25rem;
  font-family: "Cabin", sans-serif; }
  @media screen and (max-width: 768px) {
    h1, .h1 {
      font-size: 5rem; } }

h2, .h2 {
  font-size: 5rem;
  font-family: "Cabin", sans-serif; }
  @media screen and (max-width: 768px) {
    h2, .h2 {
      font-size: 3.75rem; } }

h3, .h3 {
  font-size: 3.75rem;
  font-family: "Cabin", sans-serif; }
  @media screen and (max-width: 768px) {
    h3, .h3 {
      font-size: 2.5rem; } }

h4, .h4 {
  font-size: 2.5rem;
  font-family: "Cabin", sans-serif; }
  @media screen and (max-width: 768px) {
    h4, .h4 {
      font-size: 1.25rem; } }

h5, .h5 {
  font-size: 1.25;
  font-family: "Cabin", sans-serif; }
  @media screen and (max-width: 768px) {
    h5, .h5 {
      font-size: 1.125rem; } }

h6, .h6 {
  font-size: 1.125rem;
  font-family: "Cabin", sans-serif; }
  @media screen and (max-width: 768px) {
    h6, .h6 {
      font-size: 1rem; } }

p, .p {
  color: #3a295b;
  font-size: 1rem;
  line-height: 1.3;
  font-family: "Josefin Sans", sans-serif; }
  @media screen and (max-width: 768px) {
    p, .p {
      font-size: .875rem; } }

a {
  font-size: 1rem;
  font-family: "Josefin Sans", sans-serif; }
  @media screen and (max-width: 768px) {
    a {
      font-size: .875rem; } }

input, textarea, select {
  font-family: "Josefin Sans", sans-serif; }

.image-content-block .img-content {
  display: flex;
  align-items: center; }

.image-content-block .content-container {
  flex-basis: 50%; }

.image-content-block .img-container, .image-content-block .content-container {
  order: 1; }

.image-content-block .content-padding {
  padding: 0 15px; }

.image-content-block .img-container {
  flex-basis: 50%;
  align-self: stretch;
  padding-top: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

@media screen and (max-width: 768px) {
  .image-content-block .img-content {
    flex-wrap: wrap; }
  .image-content-block .content-container, .image-content-block .img-container {
    flex-basis: 100%; }
  .image-content-block .img-container {
    padding-top: 350px; } }

.form {
  padding: 50px 15px;
  text-align: center; }
  .form form {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    max-width: 980px;
    margin: 0 auto; }
    .form form .form-field.half {
      flex-basis: 50%; }
    .form form .form-field.full {
      flex-basis: 100%; }
    .form form .form-field .form-label {
      text-align: left;
      flex-basis: 100%;
      padding: 5px 20px; }
    .form form .form-field .form-data {
      display: flex;
      padding: 5px 20px; }
      .form form .form-field .form-data .form-input-wrapper, .form form .form-field .form-data .form-textarea-wrapper, .form form .form-field .form-data .form-select-wrapper {
        display: flex;
        flex: 1; }
      .form form .form-field .form-data input, .form form .form-field .form-data textarea, .form form .form-field .form-data select {
        flex-basis: 100%;
        padding: 7px 10px;
        border: 2px solid #8B8B8B;
        transition: all .5s ease-in; }
        .form form .form-field .form-data input:focus, .form form .form-field .form-data textarea:focus, .form form .form-field .form-data select:focus {
          border: 2px solid #3a295b;
          outline: none; }
      .form form .form-field .form-data .form-select-wrapper {
        position: relative; }
        .form form .form-field .form-data .form-select-wrapper:after {
          content: "\f0dd";
          font-family: "FontAwesome";
          position: absolute;
          font-size: 22px;
          top: 3px;
          line-height: normal;
          right: 15px;
          display: block; }
      .form form .form-field .form-data select {
        appearance: none;
        border-radius: 0;
        line-height: normal; }
    .form form .buttons {
      padding: 5px 20px;
      text-align: center;
      flex-basis: 100%; }
    @media screen and (max-width: 768px) {
      .form form .form-field.half {
        flex-basis: 100%; } }

#loader {
  display: none; }
  #loader.loading {
    display: block; }
  #loader circle {
    stroke-dasharray: 50, 100;
    transform-origin: 50% 50%;
    stroke: #3a295b; }
  #loader .checkmark {
    stroke: #3a295b;
    stroke-dasharray: 100, 100;
    stroke-dashoffset: 105; }

.footer {
  background: transparent; }
  .footer hr {
    margin-top: 0; }
  .footer .wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .footer .social-accounts {
    display: flex;
    justify-content: flex-end;
    flex-basis: 25%; }
    .footer .social-accounts a {
      margin: 0 10px; }
    .footer .social-accounts img {
      height: 40px;
      width: 40px; }
    @media screen and (max-width: 768px) {
      .footer .social-accounts {
        flex-basis: auto;
        justify-content: space-around; } }
